<i18n>
{
  "en": {
    "forgotPassword":"forgotPassword"
  },
  "ja": {
    "forgotPassword":"forgotPassword"
  },
  "ko":{
    "forgotPassword":"회원가입시 입력한 이메일을 입력하세요."
  }
}
</i18n>
<template>
  <main class="forgot-password">
    <section class="main__section">
      <Alert
        v-model:alert="alert"
        v-if="!!alert.content"
        :isConfirm="true"
        @update:clear="
          () => {
            alert.content = '';
          }
        "
      />

      <Form v-slot="{ errors }" @submit="forgetPassword">
        <h2>{{ t("forgotPassword") }}</h2>
        <Field
          v-slot="{ field }"
          v-model="email"
          name="email"
          rules="required|email"
        >
          <div>
            <span class="p-float-label">
              <InputText
                id="email"
                v-model="field.value"
                v-bind="field"
                :class="{ 'p-invalid': errors.email }"
                :placeholder="l('forgotPassword')"
                type="text"
              ></InputText>
            </span>
          </div>
        </Field>
        <Button class="-button -royalBlue" type="submit">확인</Button>
      </Form>
    </section>
  </main>
</template>
<script lang="ts">
import { defineComponent, reactive, Ref, ref } from "vue";
import { Form, Field } from "vee-validate";
import InputText from "@/components/primevueCustom/InputText.vue";
import Button from "primevue/button";
// import Message from "primevue/message";
import $API from "@/services";
import { useI18n } from "vue-i18n";
import { IMessage, messageType } from "@/models/message";
import { IAlert, IResponseData, ResultDatas } from "@/models/common";
import { AxiosResponse } from "axios";
import { CommonMutationTypes } from "@/store/modules/common/mutations";
import { useStore } from "@/store";
import router from "@/router";

export default defineComponent({
  name: "Forgot Password",
  setup() {
    const email = ref("");
    const error = ref("");
    const { t } = useI18n({ useScope: "global" });
    const { t: l } = useI18n();

    const store = useStore();

    const state = reactive({
      message: {} as IMessage,
    });

    const alert: Ref<IAlert> = ref({
      type: messageType.ERROR,
      content: "",
    });

    return { email, error, t, l, state, alert, store };
  },
  methods: {
    async forgetPassword() {
      try {
        this.store.commit(CommonMutationTypes.SET_IS_LODING, true);
        const res: AxiosResponse<IResponseData<string>> =
          await $API.SignService.forgetPassword(this.email.valueOf());
        const {
          data: { ResultData },
        } = res;

        if (res.data.ResultData === ResultDatas.SUCCESS) {
          this.alert = {
            title: "비밀번호 찾기",
            content: this.t("EmailSendOK"),
            type: messageType.INFO,
            callback: () => {
              router.replace("/");
            },
          };
        } else {
          if (Array.isArray(ResultData)) return;

          this.alert = {
            content: ResultData,
            type: messageType.ERROR,
          };
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.store.commit(CommonMutationTypes.SET_IS_LODING, false);
      }
    },
  },
  components: {
    Form,
    Field,
    InputText,
    Button,
  },
});
</script>
